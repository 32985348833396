import { lazy } from "react";

const Home = lazy(() => import("./pages/Home"));
const Blog = lazy(() => import("./pages/Blog"));
const Shopper = lazy(() => import("./pages/Shopper"));
const Vendor = lazy(() => import("./pages/Vendor"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Faqs = lazy(() => import("./pages/Faqs"));
const FaqDetails = lazy(() => import("./pages/FaqDetails"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/shoppers",
    element: <Shopper />,
  },
  {
    path:"/contact",
    element: <ContactUs />
  },
  {
    path: "/vendors",
    element: <Vendor />,
  },
  {
    path: "/about",
    element: <AboutUs />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/faqs",
    element: <Faqs />,
  },
  {
    path: "/faqs/:type",
    element: <FaqDetails />,
  },
  {
    path: "*",
    element: <h1>Not Found</h1>,
  },
];

export default routes;
